import React from 'react'

const Loader = () => {
  return (
<div class="loader">
  <div class="load1"></div>
  <div class="load2"></div>
  <div class="load3"></div>
  <div class="load4"></div>
  <div class="load5"></div>
  <div class="load6"></div>
  <div class="load7"></div>
  <div class="load8"></div>
  <div class="load9"></div>
</div>
  )
}

export default Loader