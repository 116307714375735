const SHARE_PURCHASE_CONFIRMATION_TEMPLATE = (name, shareValue) => `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Welcome to Doofy Ventures! Your Share Purchase is Confirmed</title>
</head>
<body>
    <p>Dear ${name},</p>
    <br />
    <p>Welcome to the Doofy Ventures community! We are thrilled to have you as a valuable shareholder. Thank you for your trust and investment in our platform.</p>
    <br />
    <p><strong>Your Share Purchase Details:</strong></p>
    <ul>
        <li>Share Value: ${shareValue}</li>
        <li>Share Percentage: ${(shareValue * 100) / 2500000000}%</li>
    </ul>
    <br />
    <p>Your shares are now securely stored, and you can track them through our smart contract. For full transparency, you can access your purchase and future transactions using the link below:</p>
    <p><strong>Share Holders Login:</strong> <a href="https://app.doofyventures.com/">https://app.doofyventures.com/</a></p>
    <p><strong>Smart Contract Link:</strong> <a href="https://bscscan.com/address/0xf6fbda3cfc8fff69200d5b82e8c72d289dde66ed#code#L334">View on BscScan</a></p>
    <br />
    <p><strong>Release Schedule:</strong></p>
    <ul>
        <li>Release Amount: 2% of the locked value</li>
        <li>Release Interval: Every 30 days</li>
    </ul>
    <br />
    <p>This structured release ensures a steady flow of returns on your investment, providing both security and long-term benefits. You can check the status of your releases through the smart contract link provided above.</p>
    <br />
    <p>We are committed to providing you with a seamless experience and are here to assist you every step of the way. If you have any questions or need support, please don’t hesitate to contact our team.</p>
    <br />
    <p>Thank you once again for joining Doofy Ventures. We look forward to a prosperous journey together!</p>
    <br />
    <p>Best regards,</p>
    <p>Doofy Ventures Team</p>
    <p><a href="https://doofyventures.com">https://doofyventures.com</a></p>
</body>
</html>
`;

export const sendEmailAPI = async (name, email, tokens) => {
  const res2 = await fetch("/api", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_RESEND_KEY}`,
    },
    body: JSON.stringify({
      from: "Doofy Ventures <no-reply@connect.doofyventures.com>",
      to: [email],
      subject: "Welcome to Doofy Ventures! Your Share Purchase is Confirmed",
      html: SHARE_PURCHASE_CONFIRMATION_TEMPLATE(name, tokens),
    }),
  });
  if (res2.ok) {
    const data = await res2.json();
    return data;
  } else {
    return false;
  }
};
