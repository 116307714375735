import React, { useState } from "react";
import PropTypes from "prop-types";
import apis from "../context/Services";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const ImageUploadComponent = ({
  defaultImage,
  onImageUpload,
  onImageDelete,
}) => {
  const [image, setImage] = useState(null);

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImageToAPI(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        if (onImageUpload) {
          onImageUpload(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    if (onImageDelete) {
      onImageDelete();
    }
  };

  const uploadImageToAPI = async (file) => {
    const formData = new FormData();
    formData.append("image", file); // Use the key expected by your API
    try {
      let imageUpload = await apis.createProfilePicture(address,formData);
    console.log(imageUpload,"imageUploadimageUpload");
    } catch (err) {
      console.error("Error uploading the image:", err);
    }
  };

  return (
    <div className="img-upload-comp">
      <div className="wrap">
        <div className="img-wrapper">
          <img
            src={image || defaultImage}
            alt="Uploaded"
            className="uploaded-img"
          />
          <div className="icon-overlay">
            <label htmlFor="file-upload" className="upload-icon">
              <h3>+</h3>
              <input
                type="file"
                id="file-upload" // Fixed ID here
                accept="image/*"
                onChange={handleImageUpload}
              />
            </label>
          </div>
        </div>
      </div>
      {image && (
        <button className="delete-icon" onClick={handleImageDelete}>
          Delete
        </button>
      )}
    </div>
  );
};

ImageUploadComponent.propTypes = {
  defaultImage: PropTypes.string.isRequired,
  onImageUpload: PropTypes.func,
  onImageDelete: PropTypes.func,
};

export default ImageUploadComponent;
