import React, { useState, createContext, useEffect } from "react";
import { ethers } from "ethers";

/////////////////////////////////////////// BINANCE CHAIN PRESALE ////////////////////////////////////
import dvtPrivatesaleContractAddress from "../contractsData/DoofyventurePrivateSale-address.json";
import dvtPrivatesaleContractAbi from "../contractsData/DoofyventurePrivateSale.json";

import usdtContractAddress from "../contractsData/usdtToken-address.json";
import dvtTokenContractAddress from "../contractsData/DoofyToken-address.json";
import tokenContractAbis from "../contractsData/usdtToken.json";

import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ToastContainer, toast } from "react-toastify";
import { formatUnits } from "ethers/lib/utils";
import apis from "../Services";
import { BuyHistory, ClaimHistory } from "../../screens";
import { useNavigate } from "react-router-dom";
import { sendEmailAPI } from "../Services/ResendEmail";

const getProviderPresaleContract = () => {
  const providers = process.env.REACT_APP_RPC_URL_BNB;
  const provider = new ethers.providers.JsonRpcProvider(providers); //"http://localhost:8545/"
  const presaleContract = new ethers.Contract(
    dvtPrivatesaleContractAddress.address,
    dvtPrivatesaleContractAbi.abi,
    provider
  );
  return presaleContract;
};

export const Store = createContext();

export const StoreProvider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loader, setloader] = useState(false);

  const [userDatabaseData, setUserDatabaseData] = useState({
    referral_reward: 0,
    wallet_address: "",
    userName: "",
    phone_Number: "",
    email: "",
    userLevel: 1,
    referredBy:"",
    userProfileImage: "",
    totalChildrensTeam: "0",
    totalDirectUsers: "0",
    direactBusiness: "0",
    totalReferralReward: "0",
    isProfileCompleted: false,
    DirectUsersData: [],
    TeamUsersData: [],
  });

  const [userHistoryData, setUserHistoryData] = useState({
    buyHistory: [],
    claimHistory: [],
    referralHistory: [],
  });

  const [userContractData, setUserContractData] = useState({
    remainTimeInClaim: 0,
    dvtBuy_tokens: 0,
    totaldvtBuy_tokens: 0,
    dvtWallet_tokens: 0,
    smartContractPlans: [],
  });

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const GetValues = async () => {
    try {
      if (address && isConnected) {
        // setloader(true);
        console.log("checkkkkkkk");
        const providers = process.env.REACT_APP_RPC_URL_BNB;
        console.log(providers, "providersprovidersproviders");
        const provider = new ethers.providers.JsonRpcProvider(providers);
        console.log("checkkkkkkk");
        const dvtPresaleContracts = new ethers.Contract(
          dvtPrivatesaleContractAddress.address,
          dvtPrivatesaleContractAbi.abi,
          provider
        );

        const dvtTokenContracts = new ethers.Contract(
          dvtTokenContractAddress.address,
          tokenContractAbis.abi,
          provider
        );

        const TokensInContract = await dvtPresaleContracts.users(
          address?.toString()
        );

        const TokensInWallet = await dvtTokenContracts.balanceOf(
          address?.toString()
        );

        const TimeInDays = await dvtPresaleContracts.releaseInterval();

        const getAllPlans = await dvtPresaleContracts.getAllPlans();

        console.log(getAllPlans?.toString(), "getAllPlans");
        console.log(getAllPlans, "getAllPlansWS");
        console.log(
          +TokensInContract?.lastReleaseTime?.toString() + +TimeInDays,
          "TimeInDaysTimeInDaysTimeInDaysTimeInDays"
        );

        // Transform the BigNumber values into JavaScript numbers/strings
        const transformedPlans = getAllPlans.map((plan) => ({
          usdtAmount: ethers.utils.formatUnits(plan[0], 18)?.toString(), // Adjust decimals if necessary
          tokenAmount: ethers.utils.formatUnits(plan[1], 18)?.toString(), // Adjust decimals if necessary
        }));

        // Update the state with transformed plans
        setUserContractData((prevData) => ({
          ...prevData,
          smartContractPlans: transformedPlans,
        }));

        setUserContractData((prevState) => ({
          ...prevState,
          dvtWallet_tokens: formatUnits(TokensInWallet, 18)?.toString(),
          dvtBuy_tokens: formatUnits(
            TokensInContract?.lockedBalance,
            18
          )?.toString(),
          totaldvtBuy_tokens: formatUnits(
            TokensInContract?.totalBuyAmount,
            18
          )?.toString(),
          remainTimeInClaim:
            +TokensInContract?.lastReleaseTime?.toString() + +TimeInDays,
        }));
      }
    } catch (error) {
      setloader(false);
      // toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  // const addTokenToMetamask = async () => {
  //   // console.log("testttttttttttttttttttt");
  //   // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  //   // if (typeof window.ethereum !== "undefined") {
  //   //   console.log("testttttttttttttttttttt2");
  //   try {
  //     if (!isConnected) {
  //       return toast.error("Please Connect Your Wallet."), setloader(false);
  //     }

  //     const provider = new ethers.providers.Web3Provider(walletProvider);
  //     const wasAdded = await provider.provider.request({
  //       method: "wallet_watchAsset",
  //       params: {
  //         type: "ERC20",
  //         options: {
  //           address: "0xdB6675D9740f6401DcD0BB3092fa4dc88c2a0F66", // Token address
  //           symbol: "$LLC", // Token symbol
  //           decimals: 18, // Token decimals
  //           image:
  //             "https://lnbglondon.vercel.app/_next/image?url=%2Flogo.png&w=48&q=75", // Token image URL
  //         },
  //       },
  //     });

  //     // const wasAdded = await window.ethereum.request({
  //     //   method: "wallet_watchAsset",
  //     //   params: {
  //     //     type: "ERC20",
  //     //     options: {
  //     //       address: "0xdB6675D9740f6401DcD0BB3092fa4dc88c2a0F66", // Token address
  //     //       symbol: "$LLC", // Token symbol
  //     //       decimals: 18, // Token decimals
  //     //       image: "https://lnbglondon.vercel.app/_next/image?url=%2Flogo.png&w=48&q=75", // Token image URL
  //     //     },
  //     //   },
  //     // });

  //     if (wasAdded) {
  //       toast.success("Token successfully added to Metamask!");
  //     } else {
  //       toast.error("Failed to add the token.");
  //     }
  //   } catch (error) {
  //     toast.error("Failed to add token to Metamask. Please try again later.");
  //     console.error("Failed to add token to Metamask: ", error);
  //   }
  //   // } else {
  //   //   if (isMobile) {
  //   //     console.log("testttttttttttttttttttt9");
  //   //     // Metamask app is not installed, redirect to installation page
  //   //     window.open("https://metamask.app.link/dapp/https://www.lnbglondon.com/");
  //   //   } else {
  //   //     console.log("testttttttttttttttttttt10");
  //   //     // if no window.ethereum and no window.web3, then MetaMask or Trust Wallet is not installed
  //   //     alert("MetaMask or Trust Wallet is not installed. Please consider installing one of them.");
  //   //   }
  //   // }
  // };

  // const copyToClipboard = () => {
  //   const tokenAddress = lnbgCoinAddress?.address; // Your token address
  //   navigator.clipboard
  //     .writeText(tokenAddress)
  //     .then(() => {
  //       toast.success("Token address copied to clipboard!");
  //     })
  //     .catch((error) => {
  //       console.error("Failed to copy: ", error);
  //     });
  // };

  // const copyToClipboardAddress = () => {
  //   const tokenAddress = address; // Your token address
  //   navigator.clipboard
  //     .writeText(tokenAddress)
  //     .then(() => {
  //       toast.success("User address copied to clipboard!");
  //     })
  //     .catch((error) => {
  //       console.error("Failed to copy: ", error);
  //     });
  // };

  const copyToClipboardReferral = () => {
    const tokenAddress = `https://app.doofyventures.com/${address}`; // Your token address
    navigator.clipboard
      .writeText(tokenAddress)
      .then(() => {
        toast.success("Referral address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const copyWalletAddress = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success("User address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  // const plans = [
  //   {
  //     usdtAmount: ethers.utils.parseEther("3000"),
  //   },
  //   {
  //     usdtAmount: ethers.utils.parseEther("1500"),
  //   },
  //   {
  //     usdtAmount: ethers.utils.parseEther("750"),
  //   },
  //   {
  //     usdtAmount: ethers.utils.parseEther("375"),
  //   },
  //   {
  //     usdtAmount: ethers.utils.parseEther("188"),
  //   },
  //   {
  //     usdtAmount: ethers.utils.parseEther("94"),
  //   },
  // ];

  const BuyWithUSDTandUSDC = async (arrayAddresses, planIndex) => {
    if (!isConnected) {
      return toast.error("Please Connect Your Wallet.");
    }

    if (planIndex < 0 || planIndex > 5) {
      return toast.error("Please select a valid plan (0 to 5).");
    }

    try {
      setloader(true);
      // Initialize the provider and signer
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      // Create contract instances
      const USDTContracts = new ethers.Contract(
        usdtContractAddress.address,
        tokenContractAbis.abi,
        signer
      );

      const privateSale = new ethers.Contract(
        dvtPrivatesaleContractAddress.address,
        dvtPrivatesaleContractAbi.abi,
        signer
      );

      // const selectedPlan = plans[planIndex];
      const selectedPlan = await privateSale.plans(planIndex);
      const usdtAmountToApprove = selectedPlan?.usdtAmount;
      const dvtTokenToBuy = selectedPlan?.tokenAmount;

      // Check USDT balance for the first buyer
      const usdtBalance = await USDTContracts.balanceOf(arrayAddresses[0]);
      if (usdtBalance.lt(usdtAmountToApprove)) {
        return toast.error("Insufficient USDT balance.");
      }

      // Check allowance
      const allowance = await USDTContracts.allowance(
        arrayAddresses[0],
        dvtPrivatesaleContractAddress.address
      );

      if (allowance.lt(usdtAmountToApprove)) {
        // Approve the required USDT amount if allowance is insufficient
        let tokenApprove = await USDTContracts.approve(
          dvtPrivatesaleContractAddress.address,
          usdtAmountToApprove.toString()
        );
        await tokenApprove.wait();
      }

      // Call the buyTokens function with the array of addresses and the plan index
      const buying = await privateSale.buyTokens(arrayAddresses, planIndex);
      await buying.wait();
      let res = await sendEmailAPI(userDatabaseData?.userName,userDatabaseData?.email,ethers.utils.formatEther(dvtTokenToBuy?.toString())?.toString());
      console.log(res,"resresEmail");
      const bnbLink = `https://bscscan.com/tx/${buying.hash}`;
      console.log(`Transaction Hash: ${bnbLink}`);
      GetValues();
      getOneUser();
      setloader(false);
    } catch (error) {
      toast.error(`${error.reason ? error.reason : error.message}`);
      console.log(error);
      setloader(false);
    }
  };

  const claimTokens = async () => {
    try {
      setloader(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const presaleContract = new ethers.Contract(
        dvtPrivatesaleContractAddress.address,
        dvtPrivatesaleContractAbi.abi,
        signer
      );

      const start = await presaleContract.releaseTokens(address);
      // let tokens = ethers.utils.parseEther("10000000")
      // const start = await presaleContract.withdrawTokens(tokens);
      start.wait();
      await GetValues();
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  useEffect(() => {
    if (isConnected) {
      GetValues();
    }
  }, [address, isConnected]);

  // const networkChange = async () => {
  //   let chainid = process.env.NEXT_PUBLIC_CHAIN_ID_ETHEREUM;
  //   if (isConnected && chainId?.toString() !== chainid?.toString()) {
  //     console.log(chainid, chainId, "chainidchainid");
  //     useSwitchNetwork(Number(chainid));
  //     return;
  //   }
  // };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// APIS //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const getOneUser = async () => {
    try {
      if (isConnected) {
        let dbData = await apis.getOneUser(address?.toString()); //TODO:address?.toString()
        console.log(dbData, "dbDatadbDatadbData");
        setUserDatabaseData((prevState) => ({
          ...prevState,
          userName: dbData?.data?.user?.user_name,
          phone_Number: dbData?.data?.user?.phone_number,
          email: dbData?.data?.user?.email,
          userProfileImage: dbData?.data?.user?.profile_image,
          isProfileCompleted: dbData?.data?.user?.isComplete,
          userLevel: dbData?.data?.user?.level,
          referredBy: dbData?.data?.user?.parent_wallet_address,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserEventsHistory = async () => {
    try {
      if (isConnected) {
        let dbData = await apis.getUserEventsHistory(address?.toString()); //TODO:address
        console.log(dbData, "dbDatadbDssssssssssssatadbData");
        setUserHistoryData((prevState) => ({
          ...prevState,
          buyHistory: dbData?.data?.buyHistory,
          claimHistory: dbData?.data?.claimHistory,
          referralHistory: dbData?.data?.referralHistory,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserWithParents = async () => {
    if (isConnected && address) {
      try {
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: [],
          TeamUsersData: []
        }));
        const response = await apis.getUserWithParents(address?.toString());
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: response?.data?.children?.filter(
            (user) => user.level === response?.data?.user?.level + 1
          ),
          TeamUsersData: response?.data?.children?.filter(
            (user) =>
              user.level >= response?.data?.user?.level + 2 &&
              user.level <= response?.data?.user?.level + 7
          ), //&& user.level <= user.level + 7
        }));
        console.log(response, "sfdaaaaaaaaaaaaaaaaaaaaaa");
      } catch (error) {
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: [],
          TeamUsersData: []
        }));
        console.error("Error connecting user:", error);
        // throw error;
      }
    }
  };

  const getAllUserReferrals = async () => {
    if (isConnected && address) {
      try {
        const response = await apis.getAllUserReferrals(address?.toString());
        setUserDatabaseData((prevState) => ({
          ...prevState,
          totalChildrensTeam: response?.data?.totalChildren,
          totalDirectUsers: response?.data?.level1Referrals,
          wallet_address: address?.toString(),
          direactBusiness: response?.data?.business,
          totalReferralReward: response?.data?.referralReward,
        }));
        console.log(response, "responseresponseresponse222");
      } catch (error) {
        console.error("Error connecting user:", error);
        // throw error;
      }
    }
  };

  console.log(address, "addressaddress");
  console.log(isConnected, "isConnectedisConnected");
  return (
    <>
      <Store.Provider
        value={{
          getAllUserReferrals,
          getUserWithParents,
          claimTokens,
          getOneUser,
          loader,
          GetValues,
          setloader,
          getUserEventsHistory,
          getProviderPresaleContract,
          copyWalletAddress,
          // addTokenToMetamask,
          // copyToClipboard,
          copyToClipboardReferral,
          // GetValues,
          // networkChange,
          BuyWithUSDTandUSDC,
          // BuyWithETH,
          // presaleStart,
          // presaleStop,
          userHistoryData,
          userDatabaseData,
          userContractData,
          setUserDatabaseData,
        }}
      >
        {children}
      </Store.Provider>
    </>
  );
};
