import React, { useContext, useEffect, useState } from "react";
import { HeaderComponent, Sidebar } from "../components";
import Image3 from "../assets/Images/icons/share_img-3/share_img-2.png";
import { Store } from "../context/Store/Store";
import Countdown from "react-countdown";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import Loader from "../components/Loader";

const ClaimDVT = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { userContractData, GetValues, loader, claimTokens } =
    useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [countDown, setCountDown] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>Completed</p>;
    } else {
      // Render a countdown
      return (
        <span style={{ color: "white" }}>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  useEffect(() => {
    setCountDown(userContractData?.remainTimeInClaim);
  }, [address, isConnected, userContractData?.remainTimeInClaim]);

  const date = new Date(countDown);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ display: "flex" }} className="backOffice">
          {/* Sidebar */}
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />

          {/* Main Content Area */}
          <div
            style={{
              marginLeft: isSidebarOpen ? "250px" : "80px",
              width: "100%",
              transition: "margin-left 0.3s ease",
            }}
          >
            <HeaderComponent isSidebarOpen={isSidebarOpen} />

            <div className="content">
              <div style={{ padding: "20px", paddingTop: "100px" }}>
                <div className="main-cardd-buyDvt">
                  <div className="top">
                    <h3> CLAIM </h3>
                  </div>
                  <div className="cardd-dvt-con">
                    <div className="cardd-dvt">
                      <img src={Image3} alt="" width={50} />

                      <div className="plan-heading">
                        <h4>Locked Share Value</h4>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Total Share Buy</h2>
                        <p>{userContractData?.totaldvtBuy_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Locked DVT Amount</h2>
                        <p>{userContractData?.dvtBuy_tokens}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>To Be Released DVT</h2>
                        <p>{userContractData?.dvtBuy_tokens * 0.02}</p>
                      </div>

                      <div className="wrap-content-dvt">
                        <h2>Next Claim In</h2>
                        <Countdown date={date} renderer={renderer} />
                      </div>

                      <button onClick={() => claimTokens()}>Claim Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClaimDVT;
