import React from "react";
import { Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
// CSS IMPORT
import "./assets/CSS/style.css";
import "./assets/CSS/responsive.css";
import {
  BackOffice,
  BuyDVT,
  BuyHistory,
  ClaimDVT,
  ClaimHistory,
  Home,
  ReferralHistory,
} from "./screens";
import { DirectTeamComp, TotalTeamComp } from "./components";
import Loader from "./components/Loader";

const App = () => {




  const tableTransactionData = [
    {
      user_name: "JohndddddddddddddddddDoe",
      phone_number: "123-456-7890000000000",
      email: "subhisingh211015@gmail.com",
      wallet_address: "0x1234567890abcdef",
      usdtAmount:"1000",
      buyTokens:"1000"
    },
  ];

  return (
    <>
      <Routes>
        <Route path="/:referral_code" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/dashboard" element={<BackOffice />} />
        <Route path="/direct-team" element={<DirectTeamComp/>} />
        <Route path="/total-team" element={<TotalTeamComp />} />
        <Route path="/buy-dvt" element={<BuyDVT />} />
        <Route path="/claim-dvt" element={<ClaimDVT />} />
        <Route path="/claim-history" element={<ClaimHistory/>} />
        <Route path="/buy-history" element={<BuyHistory/>} />
        <Route path="/referralReward-history" element={<ReferralHistory/>} />
      </Routes>
    </>
  );
};

export default App;
