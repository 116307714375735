// HeaderComponent.js
import React, { useContext, useEffect, useState } from "react";
import { FaDiscord, FaRegCopy, FaTelegram, FaTwitter } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import {
  BsClipboardCheck,
  BsHandbag,
  BsExclamationTriangle,
} from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { Store } from "../context/Store/Store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UpdateProfileModal from "./Modals/UpdateProfileModal";

const HeaderComponent = ({ isSidebarOpen }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const { open, close } = useWeb3Modal();
  const [referralAddress, setReferralAddress] = useState();
  
  const nevigate = useNavigate();

  const {
    getOneUser,
    copyToClipboardReferral,
    userDatabaseData,
    copyWalletAddress,
    userContractData,
  } = useContext(Store);

  useEffect(() => {
    if (address && isConnected) {
      setReferralAddress(address);
    } else {
      setReferralAddress("..");
      nevigate("/")
    }
    getOneUser();
  }, [address, isConnected]);

  return (
    <>
      <header
        style={{
          left: isSidebarOpen ? "250px" : "80px",
          width: `calc(100% - ${isSidebarOpen ? "250px" : "80px"})`,
        }}
      >
        <div className="url-con">
          <img
            src="/assets/images/icons/url-icon.png"
            alt="url-icon"
            className="url-img"
          />
          <input
            placeholder={`https://app.doofyventures.com/${referralAddress?.slice(
              0,
              6
            )}...${referralAddress?.slice(-6)}`}
          />
          <FaRegCopy
            onClick={() => copyToClipboardReferral()}
            className="copy-img"
          />
        </div>

        <div className="wrap">
          <div className="header-action-con">
            <div className="clipboardcheck-con">
              <a
                href="https://x.com/doofyventures"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </div>
            <div className="handban-con">
              <a
                href="https://www.instagram.com/doofyventures/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord size={25} />
              </a>
            </div>
            <div className="exclamation-con">
              <a
                href="https://t.me/doofyventures"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram size={28} />
              </a>
            </div>
          </div>
          <div className="profile-con">
            <div className="box" onClick={() => setShowProfile(!showProfile)}>
              <h3>
                {userDatabaseData?.userName
                  ? `${userDatabaseData?.userName?.slice(
                      0,
                      1
                    )}${userDatabaseData?.userName?.slice(-1)}`
                  : "NA"}
              </h3>
            </div>
            {showProfile && (
              <>
                <div className="showProfile-con">
                  <div className="one">
                    <div className="top">
                      <p>Your DVT Profile</p>
                      <div
                        onClick={() => {
                          isConnected ? disconnect() : open();
                        }}
                        className="logout-con"
                      >
                        <p>{isConnected ? "Log OUT" : "Log In"}</p>
                        <IoIosLogOut />
                      </div>
                    </div>
                    <div
                      onClick={() => copyWalletAddress()}
                      className="wallet-address-con"
                    >
                      <img
                        src={
                          userDatabaseData?.userProfileImage
                            ? userDatabaseData?.userProfileImage
                            : "/assets/images/profile-dummy.png"
                        }
                        alt="profile"
                        style={{}}
                      />
                      <p>{`${address?.slice(0, 6)}...${address?.slice(-6)}`}</p>
                      <FaRegCopy />
                    </div>
                    <div className="wallet-address-con">
                      <h3>
                        {userDatabaseData?.userName || "No Name Available"}
                      </h3>
                      <h3>{userDatabaseData?.email || "No Email Available"}</h3>
                    </div>
                    <div className="wallet-address-con">
                    <h3>Referred By:</h3>
                    <p>{`${userDatabaseData?.referredBy?.slice(0, 6)}...${userDatabaseData?.referredBy?.slice(-6)}`}</p>
                      {/* <h3>
                        {userDatabaseData?.userName || "No Name Available"}
                      </h3>
                      <h3>{userDatabaseData?.email || "No Email Available"}</h3> */}
                    </div>

                    {/* Add Username and Email below the address */}
                    <div className="crypton-balance-con">
                      <div className="main-wrap">
                        <div className="one">
                          <p>DVT Balance</p>
                          <div className="wrap">
                            <img
                             src="/assets/images/DSmall.png"
                             alt="url-icon"
                              width={25}
                              height={25}
                            />
                            {/* <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="8.5" cy="8" r="8" fill="#FFF528" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.99551 5.07715L2.70752 8.10419L5.99549 11.2356H11.0057L14.2937 8.10419L11.0058 5.07715H5.99551ZM4.63854 8.10419L6.12183 9.5451L9.23283 6.4341H6.46523L4.63854 8.10419ZM10.7806 6.65771L7.55966 9.87866H10.536L12.3627 8.10419L10.7806 6.65771Z"
                                fill="black"
                              />
                            </svg> */}
                            <h2>{userContractData?.dvtBuy_tokens}</h2>
                          </div>
                        </div>

                        <div className="one">
                          <p>Referral rewards</p>
                          <div className="wrap">
                            <img
                              src="/assets/images/DSmall.png"
                               alt="url-icon"
                              width={25}
                              height={25}
                            />
                            {/* <svg
                              width="20"
                              height="20"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="20" cy="20" r="20" fill="#22A079" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.1987 15.3047V17.9637L22.2016 17.9665C27.1287 18.1939 30.8327 19.1752 30.8327 20.3464C30.8327 21.5205 27.1287 22.4989 22.2016 22.7263V31.25H17.8114V22.7263C12.8786 22.496 9.16602 21.5176 9.16602 20.3435C9.16602 19.1694 12.8757 18.191 17.8114 17.9637V15.3047H11.7387V11.25H28.2715V15.3047H22.1987ZM22.1987 21.9955V21.9984V22.0012C26.5487 21.8055 29.7961 21.0458 29.7961 20.1365C29.7961 19.2271 26.5516 18.4674 22.1987 18.2717V21.2415C22.0724 21.253 21.3947 21.3106 20.0395 21.3106C18.9111 21.3106 18.0928 21.2617 17.8085 21.2415V18.2689C13.4499 18.4617 10.1939 19.2243 10.1939 20.1336C10.1939 21.0458 13.447 21.8055 17.8085 21.9984V21.9955C18.0899 22.0099 18.8852 22.0415 20.0194 22.0415C21.4378 22.0415 22.0752 22.0041 22.1987 21.9955Z"
                                fill="white"
                              />
                            </svg> */}
                            <h2>0.0</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two">
                    <div className="exclusive-offer">
                      {userDatabaseData?.isProfileCompleted ? 
                      <p>Completed</p>
                      :
                      <p onClick={() => setUpdateProfileModal(true)}>Complete Your Profile</p>
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <UpdateProfileModal
          setUpdateProfileModal={setUpdateProfileModal}
          updateProfileModal={updateProfileModal}
        />
      </header>
    </>
  );
};

export default HeaderComponent;
