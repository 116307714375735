import React, { useContext, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ProfileIcon from "@mui/icons-material/Person";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { TeamIcon, ExpandMore, ExpandLess } from "@mui/icons-material";
import { FaDiscord, FaRegCopy, FaTelegram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Store } from "../context/Store/Store";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import ImageUploadComponent from "./ImageUploadComp";

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const [openSection, setOpenSection] = useState(null);
  const { userDatabaseData,copyToClipboardReferral } = useContext(Store);
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const handleClick = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const [referralAddress, setReferralAddress] = useState();

    
  useEffect(()=>{
    console.log("SideBarUseEffect");
    if(address && isConnected){
      setReferralAddress(address)
    }else{
      setReferralAddress('..')
    }
  },[address,isConnected])

  const handleImageUpload = (image) => {
    console.log("Image uploaded:", image);
  };

  return (
    <div className="main-sidebar">
      {/* Hamburger Menu Icon */}
      <IconButton
        onClick={toggleSidebar}
        style={{
          position: "fixed",
          left: isSidebarOpen ? "250px" : "80px",
          top: "20px",
          zIndex: 1000,
          color: "#fff",
          border: "1px solid #3a88a7",
          padding: "5px",
          borderRadius: "5px",
          marginLeft: "10px",
        }}
      >
        <MenuIcon />
      </IconButton>

      <div
        style={{
          width: isSidebarOpen ? "250px" : "80px",
          height: "100vh",
          backgroundColor: "#343c48",
          position: "fixed",
          left: "0",
          top: "0",
          zIndex: 500,
          borderRight: "1px solid #000",
          // padding: "10px 10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: isSidebarOpen ? "flex-start" : "center",
          transition: "width 0.3s ease",
          // overflow: "auto"
        }}
      >
        {/* Sidebar Logo */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: isSidebarOpen ? "flex-start" : "center",
            alignItems: "center",
            borderBottom: "1px solid #000",
            backgroundColor: "#303641",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            marginBottom: "20px",
            height: "80px",
          }}
        >
          {isSidebarOpen ? (
            <img
              src="/assets/images/logo.png"
              alt="Logo"
              style={{ height: "50px", width: "auto" }}
            />
          ) : (
            <span
              style={{ color: "#fff", fontSize: "18px", fontFamily: "poppins" }}
            >
              Logo
            </span>
          )}
        </div>

        {/* Sidebar Links */}
        <div className="sidebar-links-con">
          <div className="sidebar-profile">
            {/* <img
              src="/assets/images/user1.png"
              alt="user1"
              style={{ width: "3rem", height: "3rem", borderRadius: "8px" }}
            /> */}
              <ImageUploadComponent
                defaultImage={userDatabaseData?.userProfileImage ? userDatabaseData?.userProfileImage : "/assets/images/default-img.png"}
                onImageUpload={handleImageUpload}
                // onImageDelete={handleImageDelete}
                // label=""
              />
            <div class="m-0">
              <p class="m-0">Hello 👋</p>
              <h6 class="m-0 text-nowrap">{userDatabaseData?.userName}</h6>
            </div>
          </div>
          <Link to={"/dashboard"}>
            <div className="back-office-links sidebar-link active">
              <HomeIcon />
              {isSidebarOpen && (
                <span style={{ marginLeft: "10px" }}>
                  <h3>Dashboard</h3>
                </span>
              )}
            </div>
          </Link>
          {/* <div className="profile-office-links sidebar-link ">
            <ProfileIcon style={{ color: "#fff" }} />
            {isSidebarOpen && (
              <span style={{ marginLeft: "10px" }}>
                <h3>Profile</h3>
              </span>
            )}
          </div> */}

          {/* Collapsible FINANCE Links */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleClick("finance")}
              sx={{ gap: "12px" }}
            >
              <ListItemIcon>
                <GroupAddIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && (
                <ListItemText
                  style={{ color: "#fff", fontSize: "22px" }}
                  primary="Finance"
                />
              )}
              {openSection === "finance" ? (
                <ExpandLess style={{ color: "#fff" }} />
              ) : (
                <ExpandMore style={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          {isSidebarOpen && (
            <Collapse
              in={openSection === "finance"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                  component={Link}
                  to="/buy-dvt"
                >
                  <ListItemText primary="Buy Share" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                  component={Link}
                  to="/claim-dvt"
                >
                  <ListItemText primary="Claim DVT" />
                </ListItemButton>
              </List>
            </Collapse>
          )}

          {/* Collapsible FINANCE Links */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleClick("transaction")}
              sx={{ gap: "12px" }}
            >
              <ListItemIcon>
                <GroupAddIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && (
                <ListItemText
                  style={{ color: "#fff", fontSize: "22px" }}
                  primary="Transactions"
                />
              )}
              {openSection === "transaction" ? (
                <ExpandLess style={{ color: "#fff" }} />
              ) : (
                <ExpandMore style={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          {isSidebarOpen && (
            <Collapse
              in={openSection === "transaction"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                  component={Link}
                  to="/claim-history"
                >
                  <ListItemText primary="Claim History  " />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                  component={Link}
                  to="/buy-history"
                >
                  <ListItemText primary="Buy History " />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                  component={Link}
                  to="/referralReward-history"
                >
                  <ListItemText primary="Referral History " />
                </ListItemButton>
              </List>
            </Collapse>
          )}
          {/* Collapsible Team Links */}
          <ListItem disablePadding sx={{ gap: "4px" }}>
            <ListItemButton
              onClick={() => handleClick("team")}
              sx={{ gap: "12px" }}
            >
              <ListItemIcon>
                <GroupAddIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && (
                <ListItemText
                  style={{ color: "#fff", fontSize: "22px" }}
                  primary="Team"
                />
              )}
              {openSection === "team" ? (
                <ExpandLess style={{ color: "#fff" }} />
              ) : (
                <ExpandMore style={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          {isSidebarOpen && (
            <Collapse in={openSection === "team"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={Link}
                  to="/direct-team"
                  sx={{ pl: 4, color: "#fff", fontSize: "18px" }}
                >
                  <ListItemText primary="Direct" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to="/total-team"
                  sx={{ pl: 4, color: "#fff", fontSize: "18px" }}
                >
                  <ListItemText primary="Generation" />
                </ListItemButton>
              </List>
            </Collapse>
          )}

          {/* Collapsible Assets Links */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleClick("assets")}
              sx={{ gap: "12px" }}
            >
              <ListItemIcon>
                <GroupAddIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && (
                <ListItemText
                  style={{ color: "#fff", fontSize: "22px" }}
                  primary="Assets"
                />
              )}
              {openSection === "assets" ? (
                <ExpandLess style={{ color: "#fff" }} />
              ) : (
                <ExpandMore style={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          {isSidebarOpen && (
            <Collapse
              in={openSection === "assets"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="My Airdrop" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="My Ico" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="My Share Token" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="My Referral Token" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="My Share" />
                </ListItemButton>
              </List>
            </Collapse>
          )}

          {/* Collapsible Wallet Links */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleClick("wallet")}
              sx={{ gap: "12px" }}
            >
              <ListItemIcon>
                <GroupAddIcon style={{ color: "#fff" }} />
              </ListItemIcon>
              {isSidebarOpen && (
                <ListItemText
                  style={{ color: "#fff", fontSize: "22px" }}
                  primary="Wallet"
                />
              )}
              {openSection === "wallet" ? (
                <ExpandLess style={{ color: "#fff" }} />
              ) : (
                <ExpandMore style={{ color: "#fff" }} />
              )}
            </ListItemButton>
          </ListItem>

          {isSidebarOpen && (
            <Collapse
              in={openSection === "wallet"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="Airdrop" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="Ico" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="Share Token" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4, color: "#fff", fontSize: "20px" }}>
                  <ListItemText primary="Referral Token" />
                </ListItemButton>
              </List>
            </Collapse>
          )}
        </div>

        {/* FOR MOBILE */}
        <div className="mobile-content">
          {isSidebarOpen && (
            <div className="url-con">
              <img
                src="/assets/images/icons/url-icon.png"
                alt="url-icon"
                className="url-img"
              />
              <input placeholder={`CopyReferralLink/${referralAddress?.slice(
              0,
              6
            )}...${referralAddress?.slice(-6)}`} />
              <FaRegCopy onClick={() => copyToClipboardReferral()} className="copy-img" />
            </div>
          )}
          {isSidebarOpen && (
            <div className="header-action-con">
              <div className="clipboardcheck-con">
                <FaTwitter />
              </div>
              <div className="handban-con">
                <FaDiscord size={25} />
              </div>
              <div className="exclamation-con">
                <FaTelegram size={28} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
